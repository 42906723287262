import React from 'react'
import Link from 'gatsby-link'
import { Helmet } from 'react-helmet'

import Seo from './../components/Seo'

function notFound404() {
  return (
    <div className="terms basic">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Seo meta="404" />
      <h3>{`Ooops`}</h3>
      <p>{`It looks like you've put in the wrong address. Not a problem – we all do that sometimes.`}</p>
      <p>
        {`Here's a link to our `}
        <Link to="/">{`home page`}</Link>
        {` to get you back on track.`}
      </p>
      <div className="logo-img center">
        <Link to="/">
          <img
            src="/assets/images/dr-lingua.jpg"
            alt="Dr Lingua logo"
            width="400"
            height="auto"
            data-pin-description="Dr Lingua logo"
          />
        </Link>
      </div>
    </div>
  )
}

export default notFound404
